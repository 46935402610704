<template>
    <section>
        <div id="formUserModal" uk-modal esc-close="false" bg-close="false">
            <form class="uk-modal-dialog" data-vv-scope="user-form">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">User Form</h2>
                </div>

                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label">Full Name <b class="uk-text-danger">*</b></label>
                        <input
                            class="uk-input"
                            :class="{'uk-form-danger': errors.has('user-form.fullname')}"
                            name="fullname"
                            type="text"
                            v-model="userForm.fullname"
                            v-validate="'required'"
                            placeholder="Full Name"
                        >
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('user-form.fullname')">{{ errors.first('user-form.fullname') }}</span>
                    </div>

                    <div class="uk-margin">
                        <label class="uk-form-label uk-margin-small-right">Login via:</label>
                        <label class="uk-form-label uk-margin-small-right">
                            <input
                                class="uk-radio"
                                name="login_via"
                                type="radio"
                                v-bind:value="true"
                                v-model="userForm.login_via_email"
                            >
                            Email
                        </label>
                        <label class="uk-form-label uk-margin-small-right">
                            <input
                                class="uk-radio"
                                name="login_via"
                                type="radio"
                                v-bind:value="false"
                                v-model="userForm.login_via_email"
                            >
                            Phone number
                        </label>
                    </div>

                    <div class="uk-margin">
                        <label class="uk-form-label">Email <b v-if="userForm.login_via_email" class="uk-text-danger">*</b></label>
                        <input
                            class="uk-input"
                            :class="{'uk-form-danger': errors.has('user-form.email')}"
                            name="email"
                            type="email"
                            v-model="userForm.email"
                            v-validate="{required: userForm.login_via_email}"
                            placeholder="Email"
                        >
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('user-form.email')">
                            {{ errors.first('user-form.email') }} Switch login mode to make this field optional.
                        </span>
                    </div>

                    <div class="uk-margin">
                        <label class="uk-form-label">Phone Number <b v-if="!userForm.login_via_email" class="uk-text-danger">*</b></label>
                        <input
                            class="uk-input"
                            :class="{'uk-form-danger': errors.has('user-form.phone_number')}"
                            name="phone_number"
                            type="text"
                            v-model="userForm.phone_number"
                            v-validate="{required: !userForm.login_via_email}"
                            placeholder="Phone Number"
                        >
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('user-form.phone_number')">
                            {{ errors.first('user-form.phone_number') }} Switch login mode to make this field optional.
                        </span>
                    </div>

                    <div class="uk-margin">
                        <label class="uk-form-label"><span v-if="formIsEdit">Change </span>Password <b v-if="!formIsEdit" class="uk-text-danger">*</b></label>
                        <input
                            class="uk-input"
                            :class="{'uk-form-danger': errors.has('user-form.password')}"
                            name="password"
                            type="password"
                            v-model="userForm.password"
                            v-validate="{required: !formIsEdit}"
                            placeholder="Password"
                        >
                        <span v-if="!formIsEdit" class="uk-text-small uk-text-danger" v-show="errors.has('user-form.password')">{{ errors.first('user-form.password') }}</span>
                        <p class="uk-text-small uk-margin-remove-bottom">Password must consists of:</p>
                        <ul class="uk-margin-remove-top">
                            <li>
                                <p class="uk-width-1-1 uk-flex-center uk-text-small uk-margin-remove">
                                    {{ 'Minimum 8 characters' }}
                                    <img v-if="passwordErrors.minimalChars" :src="`${images}/check-circle.svg`" alt="" width="15" height="15">
                                    <img v-else :src="`${images}/alert_red.svg`" alt="" width="15" height="15">
                                </p>
                            </li>
                            <li>
                                <p class="uk-width-1-1 uk-flex-center uk-text-small uk-margin-remove">
                                    {{ '1 uppercase letter (A-Z)' }}
                                    <img v-if="passwordErrors.hasUpperCase" :src="`${images}/check-circle.svg`" alt="" width="15" height="15">
                                    <img v-else :src="`${images}/alert_red.svg`" alt="" width="15" height="15">
                                </p>
                            </li>
                            <li>
                                <p class="uk-width-1-1 uk-flex-center uk-text-small uk-margin-remove">
                                    {{ '1 lowercase letter (a-z)' }}
                                    <img v-if="passwordErrors.hasLowerCase" :src="`${images}/check-circle.svg`" alt="" width="15" height="15">
                                    <img v-else :src="`${images}/alert_red.svg`" alt="" width="15" height="15">
                                </p>
                            </li>
                        </ul>
                    </div>

                    <div class="uk-margin">
                        <label class="uk-form-label">Role <b class="uk-text-danger">*</b></label>
                        <multiselect
                            v-model="userForm.roles"
                            placeholder="Select Role"
                            label="name"
                            name="role"
                            track-by="name"
                            v-validate="'required'"
                            :options="roleOptions"
                        ></multiselect>
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('user-form.role')">{{ errors.first('user-form.role') }}</span>
                    </div>

                    <div v-if="userForm.roles.name==='Candidate'" class="uk-margin">
                        <label class="uk-form-label">Partners</label>
                        <multiselect
                            v-model="userForm.partner_ids"
                            placeholder="Type to search company partner"
                            label="name"
                            name="partner"
                            track-by="name"
                            :options="partnerOptions"
                            :multiple="true"
                            :close-on-select="false"
                            :searchable="true"
                            :loading='isFetching'
                            :internal-search="false"
                            :options-limit="50"
                            @search-change="setPartnerValues"
                        ></multiselect>
                    </div>

                    <div v-if="source === 'employer'" class="uk-margin">
                        <label class="uk-form-label">Company</label>
                        <multiselect
                            v-model="userForm.company_id"
                            placeholder="Type to search company"
                            label="name"
                            name="company"
                            track-by="name"
                            :options="companyOptions"
                            :searchable="true"
                            :loading='isFetching'
                            :internal-search="false"
                            :options-limit="50"
                            @search-change="setCompanyValues"
                        ></multiselect>
                    </div>

                    <div v-if="userForm.company_id && source === 'administrator'" class="uk-margin">
                        <label class="uk-form-label">Default Company</label>
                        <multiselect
                            v-model="userForm.company_id"
                            placeholder="Type to search company"
                            label="name"
                            name="company"
                            track-by="name"
                            :disabled="true"
                            :options="companyOptions"
                            :searchable="true"
                            :loading='isFetching'
                            :internal-search="false"
                            :options-limit="50"
                            @search-change="setCompanyValues"
                        ></multiselect>
                    </div>

                    <div v-if="userForm.company_id && source === 'administrator'" class="uk-margin">
                        <label class="uk-form-label">Assigned Company</label>
                        <div class="uk-flex">
                            <multiselect
                                v-model="userForm.company_options"
                                label="name"
                                track-by="_id"
                                :multiple="true"
                                :disabled="true"
                                :options="companyOptions"
                            ></multiselect>
                        </div>
                    </div>

                    <div v-if="source === 'employer' || userForm.roles.name === 'Employer'" class="uk-margin">
                        <label class="uk-form-label">Company Role</label>
                        <multiselect
                            v-model="userForm.client_role_id"
                            placeholder="Type to search company role"
                            label="name"
                            name="company"
                            track-by="name"
                            :options="internalRoleOptions"
                            :searchable="true"
                            :loading='isFetching'
                            :internal-search="false"
                            :options-limit="50"
                            :disabled="!userForm.company_id"
                            @search-change="setRoleValues"
                        ></multiselect>
                    </div>

                    <div v-if="source === 'employer' || userForm.roles.name === 'Employer'" class="uk-margin">
                        <label class="uk-form-label">Office Options (Property)</label>
                        <multiselect
                            v-model="form.client_office_options"
                            placeholder="Type to search property"
                            label="name"
                            name="property_options"
                            track-by="name"
                            :options="officeOptions"
                            :multiple="true"
                            :searchable="true"
                            :loading='isFetching'
                            :internal-search="true"
                            :options-limit="50"
                            :close-on-select="false"
                            :disabled="!userForm.company_id"
                            @search-change="setPropertyValues"
                        ></multiselect>
                    </div>

                    <div v-if="source === 'employer' || userForm.roles.name === 'Employer'" class="uk-margin">
                        <label class="uk-form-label">Selected Office (Property)</label>
                        <multiselect
                            v-model="form.client_office_id"
                            placeholder="Type to search property"
                            label="name"
                            name="property"
                            track-by="name"
                            :options="form.client_office_options"
                            :searchable="false"
                            :loading='isFetching'
                            :internal-search="true"
                            :options-limit="50"
                            :disabled="!userForm.company_id"
                        ></multiselect>
                    </div>

                    <div v-if="formIsEdit" class="uk-margin">
                        <label class="uk-form-label">Verification Code <b class="uk-text-danger">*</b></label>
                        <input
                            class="uk-input"
                            :class="{'uk-form-danger': errors.has('user-form.verification_code')}"
                            name="notes"
                            type="text"
                            v-model="userForm.verification_code"
                            placeholder="Verification Code"
                        >
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('user-form.verification_code')">{{ errors.first('user-form.verification_code') }}</span>
                    </div>

                    <div class="uk-margin">
                        <label class="uk-form-label">Notes</label>
                        <input
                            class="uk-input"
                            :class="{'uk-form-danger': errors.has('user-form.notes')}"
                            name="notes"
                            type="text"
                            v-model="userForm.notes_superadmin"
                            placeholder="Notes"
                        >
                    </div>

                    <div class="uk-margin">
                        <label class="uk-form-label">
                            <input
                                class="uk-checkbox uk-margin-small-right"
                                name="user_verified"
                                type="checkbox"
                                v-model="userForm.user_verified"
                            >
                            User Verified
                        </label>
                    </div>
                </div>

                <div class="uk-modal-footer uk-text-right">
                    <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
                    <button v-else class="uk-button uk-button-primary" type="button" @click="saveUser">Save</button>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { mapActions } from 'vuex';

export default {
    props: {
        userForm: {
            type: Object,
            required: true
        },
        roleOptions: {
            type: Array,
            required: false
        },
        partnerOptions: {
            type: Array,
            required: false
        },
        companyOptions: {
            type: Array,
            required: false
        },
        internalRoleOptions: {
            type: Array,
            required: false
        },
        officeOptions: {
            type: Array,
            required: false
        },
        isFetching: {
            type: Boolean,
            required: false
        },
        formIsEdit: {
            type: Boolean,
            required: true
        },
        isLoading: {
            type: Boolean,
            required: false
        },
        source: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            passwordErrors: {
                hasUpperCase: false,
                hasLowerCase: false,
                minimalChars: false
            },
            form: {
                client_office_id: null,
                client_office_options: []
            },
            loading: this.isLoading
        };
    },
    watch: {
        'userForm.password'(nVal){
            const regexUpper = /([A-Z])/g;
            const regexLower = /([a-z])/g;
            const hasUpperCase = regexUpper.test(nVal);
            const hasLowerCase = regexLower.test(nVal);
            const minimalChars = nVal == undefined ? false : nVal.length >= 8 || false ; 
            this.passwordErrors.hasUpperCase = hasUpperCase;
            this.passwordErrors.hasLowerCase = hasLowerCase;
            this.passwordErrors.minimalChars = minimalChars;
        },
        'form.client_office_options'(val){
            const office = val.find(
                e => e.value === this.form.client_office_id.value
            );

            if (!office) {
                this.form.client_office_id = null;
            }
        }
    },
    mounted() {
        if (this.source === 'employer' || this.userForm.roles.name === 'Employer') {
            this.form.client_office_id = this.userForm.client_office_id;
            this.form.client_office_options = [...this.userForm.client_office_options];
        }
        window.UIkit.modal('#formUserModal').show();
    },
    methods: {
        ...mapActions({
            createUser: 'user/createUser',
            updateUser: 'user/updateUser',
        }),
        hideModal(){
            window.UIkit.modal('#formUserModal').$destroy(true);
            this.$emit("hideModal");
        },
        async saveUser(){
            try {
                const validate = await this.$validator.validateAll('user-form');
                if (!validate || this.$validator.errors.any()) return;

                this.loading = true;
                this.userForm.roles = this.userForm.roles.value;
                this.userForm.company_id = this.userForm.company_id ? this.userForm.company_id.value : null;
                this.userForm.client_role_id = this.userForm.client_role_id ? this.userForm.client_role_id.value : null;
                this.userForm.client_office_id = this.form.client_office_id ? this.form.client_office_id.value : null;
                this.userForm.client_office_options = this.form.client_office_options.length > 0 ?
                    this.form.client_office_options.map(opt => opt.value) : [];
                this.userForm.partner_ids = this.userForm.partner_ids.map(
                    partner => partner.value
                );
                if (!this.userForm.password) delete this.userForm.password;
                if (!this.userForm.verification_code) delete this.userForm.verification_code;
                if (!this.userForm.email) this.userForm.email = null;
                if (!this.userForm.phone_number) this.userForm.phone_number = null;
                if (this.source === 'administrator') delete this.userForm.company_options;

                const response = this.formIsEdit
                    ? await this.updateUser(this.userForm)
                    : await this.createUser(this.userForm);

                this.loading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('User saved!');
                    this.hideModal();
                    await this.$emit("successAction");
                } else {
                    notificationDanger('Failed to save user.');
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        setPartnerValues(){
            this.$emit("setPartnerValues");
        },
        setCompanyValues(query){
            this.$emit("setCompanyValues", query);
        },
        setRoleValues(query){
            this.$emit("setRoleValues", query);
        },
        setPropertyValues(query){
            this.$emit("setPropertyValues", query);
        },
    }

};
</script>
